import React from 'react'
import Helmet from 'react-helmet'
import { colors } from '../utils/theme'
import useSiteMetadata from './SiteMetadata'

const Metadata = ({
  
  titleTemplate = 'LoanSnap | %s',
  title='LoanSnap',
  children,
  description = 'Stop losing money today.',
  colorScheme = 'light',
  image,
  tags,
}) => {

  const { siteUrl } = useSiteMetadata()
  image = image || `${siteUrl}/img/LoanSnap_OG.png`

  return (
    <Helmet titleTemplate={titleTemplate}>
      <html lang="en" />
      <title>{title}</title>
      <meta name="og:type" content="business.business" />
      <meta name="og:title" content={title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="keywords" content={tags} />
      <meta name="og:image" content={image} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />


      {/* ============ FAVICON / ICONS ================ */}
      <meta name="theme-color" content={colors.shamrock}/>
      <meta name="msapplication-TileColor" content={colors.shamrock}/>
      <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color={colors.shamrock}/>
      <link rel="shortcut icon" type="image/x-icon" href={`/static/favicon/${colorScheme}/favicon.ico`}/>
      <link rel="icon" type="image/png" sizes="16x16" href={`${siteUrl}/favicon/${colorScheme}/favicon-16.png`}/>
      <link rel="icon" type="image/png" sizes="32x32" href={`${siteUrl}/favicon/${colorScheme}/favicon-32.png`}/>
      <link rel="icon" type="image/png" sizes="96x96" href={`${siteUrl}/favicon/${colorScheme}/favicon-96.png`}/>
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${siteUrl}/favicon/${colorScheme}/favicon-57.png`}/>
      <link rel="apple-touch-icon" sizes="72x72" href={`${siteUrl}/favicon/${colorScheme}/favicon-72.png`}/>
      <link rel="apple-touch-icon" sizes="60x60" href={`${siteUrl}/favicon/${colorScheme}/favicon-60.png`}/>
      <link rel="apple-touch-icon" sizes="76x76" href={`${siteUrl}/favicon/${colorScheme}/favicon-76.png`}/>
      <link rel="apple-touch-icon" sizes="120x120" href={`${siteUrl}/favicon/${colorScheme}/favicon-120.png`}/>
      <link rel="apple-touch-icon" sizes="152x152" href={`${siteUrl}/favicon/${colorScheme}/favicon-152.png`}/>
      <link rel="icon" sizes="128x128" href={`${siteUrl}/favicon/${colorScheme}/favicon-128.png`}/>
      <link rel="icon" sizes="128x128" href={`${siteUrl}/favicon/${colorScheme}/favicon-192.png`}/>
      <link rel="icon" sizes="196x196" href={`${siteUrl}/favicon/${colorScheme}/favicon-196.png`}/>
      <meta name="msapplication-square70x70logo" content={`${siteUrl}/favicon/${colorScheme}/mstile-70.png`}/>
      <meta name="msapplication-square144x144logo" content={`${siteUrl}/favicon/${colorScheme}/mstile-144.png`}/>
      <meta name="msapplication-square150x150logo" content={`${siteUrl}/favicon/${colorScheme}/mstile-150.png`}/>
      <meta name="msapplication-square310x310logo" content={`${siteUrl}/favicon/${colorScheme}/mstile-310.png`}/>

      {children}

      <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
    </Helmet>
  )
}

export default Metadata

import React, { useContext } from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import { default as AnalyticsContext } from '../providers/analytics-provider'
import get from 'lodash/get'

const CascadeLink = ({href, to, analyticsTargetName, onClick, children, ...otherProps}) =>  {
  const context = useContext(AnalyticsContext)
  const _onClick = (e) => {
    let targetName = 'Missing Click Target Name'
    if (analyticsTargetName) {
      targetName = analyticsTargetName
    } else if (typeof children === 'string') {
      targetName = children
    }
    context.analytics.click({target: targetName, destination: href || to })
    if(onClick) {
      onClick(e)
    }
  }

  const maintainQueryParams = (hrefOrTo, context) => {
    // We want any existing query params to continue to exist after navigation
    // Grab any url params from location
    let currentSearch = get(context, 'location.search')
    if (!currentSearch) {
      return hrefOrTo
    }

    currentSearch = currentSearch.replace('?', '')

    if (!currentSearch) {
      return hrefOrTo
    }
    // don't destroy existing search if there is one on the link
    var separator = hrefOrTo.indexOf('?') !== -1 ? "&" : "?"
    return hrefOrTo + separator + currentSearch
  }


  if (href) {
    return (
      <a href={maintainQueryParams(href, context)} {...otherProps} onClick={_onClick}>
        {children}
      </a>
    )
  }
  return (
    <Link to={maintainQueryParams(to, context)} {...otherProps} onClick={_onClick}>
      {children}
    </Link>
  )
}

CascadeLink.propTypes = {
  analyticsTargetName: PropTypes.string
}

//  cascade link that absorbs style of containing element's text color
export const ChameleonCascadeLink = (props) => (
  <CascadeLink style={{color: 'inherit'}} {...props} />
)

export default CascadeLink

import { rhythm } from '../utils/typography'

export const colors = {
  // Primary
  grey: '#385C5E',
  green: '#32B049',

  // Secondary
  tan: '#EDECD2',
  blueGreen: '#3A9095',
  white: '#FFFFFF',

  // Tertiary
  mediumGreen: '#1A8A69',
  darkGreen: '#034953',
  yellow: '#FEC744',
  orange: '#F1592A',
  lightGrey: '#D8D8D8',

  // Other
  facebookBlue: '#4267B2',

  // Todo: Remove
  shamrock: '#32B049',
  darkGrey: '#34393d',
  mediumGrey: '#AAAAAA',
  anotherGrey: '#B3B3B3',
  lightGrey: '#F2F2F2',
  veryLightGrey: '#F9F9F9',

  otherGrey: '#9A9A89',
}

export const presets = {
  SmallMobile: '@media (min-width: 300px)',
  Mobile: '@media (min-width: 415px)',
  Phablet: '@media (min-width: 550px)',
  Tablet: '@media (min-width: 750px)',
  Desktop: '@media (min-width: 1000px)',
  Hd: '@media (min-width: 1280px)'
}

export const metrics = {
  defaultMargin: rhythm(2),
  defaultPadding: rhythm(3),
  defaultMobilePadding: rhythm(1),
  contentWidth: "1140px",
  defaultSectionTopMargin: '40px',
}

export const themeStyles = {
  contentWidth: { width: "100%", maxWidth: metrics.contentWidth, margin: '0 auto'},
  sectionTopMargin: { marginTop: metrics.defaultSectionTopMargin },
  sectionHeader: {
    fontSize: '30px',
    color: colors.grey,
    textAlign: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    [presets.Mobile]: {
      marginTop: metrics.defaultSectionTopMargin,
      marginBottom: '30px',
    },
  },
  boxShadow: { boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.15)`},
  strong: {
    fontWeight: 600,
  },
  elementMargin: {
    'sm': '10px',
    'md': '20px',
    'lg': '30px',
  },
  textPadding: {
    paddingLeft: metrics.defaultMobilePadding,
    paddingRight: metrics.defaultMobilePadding,
    paddingTop: rhythm(1),
    paddingBottom: rhythm(1),
    [presets.Mobile]: {
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
      paddingTop: rhythm(1),
      paddingBottom: rhythm(1),
    },
  }
}
